<template>
  <v-container
    class="pa-0"
    style="max-width: 220px"
    fluid
  >
    <v-card
      class="pa-0 ma-0 d-flex flex-direction-row align-center"
      outlined
    >
      <v-col class="shrink pa-0">
        <v-btn
          text
          min-width="28px"
          max-width="38px"
          class="rounded-r-0"
          @click="previousTime"
        >
          <v-icon>{{ icons.previous }}</v-icon>
        </v-btn>
      </v-col>
      <v-col class="pa-0">
        <v-dialog
          v-if="dialog"
          v-model="showDateTimePicker"
          width="auto "
          @keydown.esc="showDateTimePicker = false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ma-0 pa-0 rounded-0"
              width="100%"
              text
              v-bind="attrs"
              v-on="on"
            >
              {{ dateLabel }}
            </v-btn>
          </template>
          <date-time-picker
            v-model="datePicker"
            :min="min"
            :max="max"
            @close="showDateTimePicker = false"
          />
        </v-dialog>

        <v-menu
          v-else
          v-model="showDateTimePicker"
          :close-on-content-click="false"
          offset-y
          nudge-left="74"
          :min-width="$vuetify.breakpoint.mobile ? 290 : 'auto'"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ma-0 pa-0 rounded-0"
              width="100%"
              text
              v-bind="attrs"
              v-on="on"
            >
              {{ dateLabel }}
            </v-btn>
          </template>
          <date-time-picker
            v-model="datePicker"
            :min="min"
            :max="max"
            @close="showDateTimePicker = false"
          />
        </v-menu>
      </v-col>
      <v-col class="shrink pa-0">
        <v-btn
          text
          min-width="28px"
          max-width="38px"
          class="rounded-l-0"
          @click="nextTime"
        >
          <v-icon>{{ icons.next }}</v-icon>
        </v-btn>
      </v-col>
    </v-card>
  </v-container>
</template>

<script>
// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';

import DateTimePicker from '@/components/DateTimePicker.vue';

export default {
  name: 'DateTimeIterator',
  components: {
    DateTimePicker,
  },
  props: {
    placeholder: { type: String, default: '' },
    value: { type: String, default: '' },
    dialog: { type: Boolean, default: false },
    max: { type: String, default: undefined },
    min: { type: String, default: undefined },
  },
  data: () => ({
    showDateTimePicker: false,
    icons: {
      next: mdiChevronRight,
      previous: mdiChevronLeft,
    },
    timeIntervalInMinutes: 5,
  }),
  computed: {
    datePicker: {
      get() {
        return this.value || this.$moment().toISOString();
      },
      set(newDate) {
        this.$emit('input', newDate);
      },
    },
    dateLabel() {
      if (this.value) {
        const date = this.$moment(this.value);

        if (date.isValid()) {
          return date.format('DD/MM/YYYY HH:mm');
        }
      }

      return this.placeholder;
    },
  },
  methods: {
    nextTime() {
      const value = this.value || this.$moment().toISOString();
      const date = this.$moment(value).add(this.timeIntervalInMinutes, 'minutes');

      this.$emit('input', date.toISOString());
    },
    previousTime() {
      const value = this.value || this.$moment().toISOString();
      const date = this.$moment(value).subtract(this.timeIntervalInMinutes, 'minutes');

      this.$emit('input', date.toISOString());
    },
  },
};
</script>

<style>
.v-dialog {
  margin: 0%;
}
</style>
