<template>
  <v-card>
    <v-tabs grow>
      <v-tab>
        <v-icon>
          {{ icons.calendar }}
        </v-icon>
      </v-tab>
      <v-tab>
        <v-icon>
          {{ icons.clock }}
        </v-icon>
      </v-tab>

      <v-tab-item>
        <v-date-picker
          v-model="datePicker"
          class="rounded-0"
          color="primary"
          flat
          :min="minDate"
          :max="maxDate"
          :locale="$i18n.locale"
        />
      </v-tab-item>

      <v-tab-item>
        <v-time-picker
          v-model="timePicker"
          flat
          format="24hr"
          class="rounded-0"
          color="primary"
        />
      </v-tab-item>
    </v-tabs>
    <v-card-actions>
      <v-spacer />
      <v-btn
        depressed
        outlined
        color="neutral"
        @click="cancel"
      >
        {{ $t('deconve.cancel') }}
      </v-btn>
      <v-btn
        depressed
        color="primary"
        @click="setDateTime"
      >
        {{ $t('deconve.ok') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { mdiCalendar, mdiClock } from '@mdi/js';

export default {
  name: 'DateTimePicker',
  props: {
    placeholder: { type: String, default: '' },
    value: { type: String, default: '' },
    max: { type: String, default: undefined },
    min: { type: String, default: undefined },
  },
  data: () => ({
    showCalendar: false,
    selectedDate: undefined,
    selectedTime: undefined,
    icons: {
      calendar: mdiCalendar,
      clock: mdiClock,
    },
  }),
  computed: {
    datePicker: {
      get() {
        if (this.selectedDate) return this.selectedDate;

        return this.getDate(this.value ? new Date(this.value) : new Date());
      },
      set(newDate) {
        this.selectedDate = newDate;
      },
    },
    timePicker: {
      get() {
        if (this.selectedTime) return this.selectedTime;

        return this.getTime(this.value ? new Date(this.value) : new Date());
      },
      set(newTime) {
        this.selectedTime = newTime;
      },
    },
    minDate() {
      return this.min ? this.getDate(new Date(this.min)) : undefined;
    },
    maxDate() {
      return this.getDate(this.max ? new Date(this.max) : new Date());
    },
  },
  methods: {
    getDate(date) {
      // v-date-picker expects date in YYYY-MM-DD format. Because of the user locale, we can not
      // 'just use' ISOFormat
      let month = `${date.getMonth() + 1}`;
      let day = `${date.getDate()}`;
      const year = date.getFullYear();

      if (month.length < 2) { month = `0${month}`; }

      if (day.length < 2) { day = `0${day}`; }

      return [year, month, day].join('-');
    },
    getTime(date) {
      let hour = `${date.getHours()}`;
      let minute = `${date.getMinutes()}`;

      if (hour.length < 2) { hour = `0${hour}`; }

      if (minute.length < 2) { minute = `0${minute}`; }

      return [hour, minute].join(':');
    },
    resetDateAndTime() {
      this.selectedDate = undefined;
      this.selectedTime = undefined;
    },
    setDateTime() {
      const localDateStr = `${this.datePicker}T${this.timePicker}`;

      const dateIsoFormat = new Date(localDateStr).toISOString();

      this.$emit('input', dateIsoFormat);
      this.$emit('close');
      this.resetDateAndTime();
    },
    cancel() {
      this.resetDateAndTime();
      this.$emit('close');
    },
  },
};
</script>
