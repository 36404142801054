<template>
  <v-container
    fluid
    class="pa-0 fill-height"
  >
    <!-- Using person id as key to let vue handles the update process -->
    <unit-register
      v-if="currentMode === mode.edit"
      :key="unitId"
      :unit-id="unitId"
      @onGoBackButtonClicked="onGoBackButtonClicked"
      @canceled="onEditCanceled"
      @deleted="onUnitDeleted"
      @edited="onUnitEdited"
    />
    <unit-register
      v-else-if="currentMode === mode.register"
      @onGoBackButtonClicked="onGoBackButtonClicked"
      @registered="onUnitRegistered"
    />
    <unit-viewer
      v-else
      :key="unitId"
      :unit-id="unitId"
      @onEditButtonClicked="onEditButtonClicked"
      @onGoBackButtonClicked="onGoBackButtonClicked"
    />
    <v-snackbar
      v-model="enableAlert"
      data-cy="snackbar"
      color="primary"
    >
      {{ alertMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="enableAlert = false"
        >
          <v-icon color="white">
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
// Copyright (C) 2022 Deconve Technology. All rights reserved.

import UnitRegister from './UnitRegister.vue';
import UnitViewer from './UnitViewer.vue';

export default {
  name: 'UnitDetails',
  components: {
    UnitRegister,
    UnitViewer,
  },
  data() {
    return {
      mode: {
        register: 0,
        edit: 1,
        view: 2,
      },
      currentMode: 0,
      alertMessage: '',
      enableAlert: false,
      fromRoute: undefined,
      unitId: undefined,
    };
  },
  watch: {
    unitId() {
      if (this.unitId) {
        this.currentMode = this.mode.view;
      } else {
        this.currentMode = this.mode.register;
      }
    },
  },
  created() {
    this.unitId = this.$route.params?.unitId;
  },
  methods: {
    onEditButtonClicked() {
      this.currentMode = this.mode.edit;
    },
    goBack() {
      if (this.fromRoute?.name && this.fromRoute.name !== 'unit-register') {
        this.$router.go(-1);
      } else {
        this.$router.push({ name: 'units' });
      }
    },
    onGoBackButtonClicked() {
      if (this.currentMode === this.mode.edit) {
        this.currentMode = this.mode.view;
      } else {
        this.goBack();
      }
    },
    onEditCanceled() {
      this.currentMode = this.mode.view;
    },
    onUnitDeleted() {
      this.goBack();
    },
    onUnitEdited() {
      this.currentMode = this.mode.view;
      this.enableAlert = true;
      this.alertMessage = this.$t('deconve.unitEdited');
    },
    onUnitRegistered(unitId) {
      this.currentMode = this.mode.view;
      this.$router.push({ name: 'unit-details', params: { unitId } });
      this.unitId = unitId;
      this.enableAlert = true;
      this.alertMessage = this.$t('deconve.unitAdded');
    },
  },
  beforeRouteEnter(to, from, next) {
    // Saves the fromRoute info. The component public data is accessed by the `vm` object
    next((vm) => {
      // eslint-disable-next-line no-param-reassign
      vm.fromRoute = from;
    });
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.unitId = this.$route.params?.unitId;
  },
};
</script>
